




























































import { Component, Watch } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import { BaseData } from '@/store/student/types'
import MediaUploader from '@/components/common/MediaUploader.vue'
import CustomSelect from '@/components/common/CustomSelect.vue'
import Unescape from '@/common/unescape'
import { GET_BASE_DATA_EDIT, SET_BASE_DATA } from '@/store/student/actions'
import SubmitSuccess from '@/components/forms/SubmitSuccess.vue'
import { SimpleServerResponse, User } from '@/store/types'
import Rights, { STUDENT_OWN_VIEW } from '@/common/rights'
import BaseInput from '@/components/common/BaseInput.vue'
import { Form, KeyValue } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import BaseForm from '@/components/forms/BaseForm.vue'
import Enums from '@/common/Enums'
import { hasRight } from '@/utils/rights'
import { GET_COURSES_OF_STUDY } from '@/store/jobAd/actions'
import { CourseOfStudy } from '@/store/jobAd/types'
import PreviewHandler from '@/components/common/PreviewHandler.vue'
import MetaComponent from '@/common/MetaComponent.vue'

@Component({
  components: { PreviewHandler, BaseForm, BaseInput, CustomSelect, MediaUploader, SubmitSuccess }
})
export default class ProfileEditor extends MetaComponent {
  @State('studentState.baseInformation') baseData: BaseData

  @Action(GET_BASE_DATA_EDIT)
  public getBaseInformation: () => Promise<SimpleServerResponse>

  @Action(SET_BASE_DATA)
  public setBaseInformation: (data: BaseData) => Promise<void>

  @Action(GET_COURSES_OF_STUDY)
  getAllCourses: () => Promise<Array<CourseOfStudy>>

  @Getter('userGetter') userGetter: User

  @Watch('locale')
  onValueChange (): void {
    this.coursesOptions = this.mapCoursesToKeyValue()
  }

  courses: CourseOfStudy[] = []
  coursesOptions: KeyValue[] = []

  baseInformation: BaseData = {
    salutation: '',
    title: '',
    firstname: '',
    lastname: '',
    email: '',
    fallbackMail: '',
    courseOfStudy: ''
  }

  get locale (): string {
    return this.$i18n.locale
  }

  get salutations (): KeyValue[] {
    return Enums.SALUTATIONS.map(key => {
      return {
        value: key,
        text: this.$i18n.t('personal-information.SALUTATIONS.' + key).toString()
      } as KeyValue
    })
  }

  get titles (): KeyValue[] {
    return Enums.TITLES.map(key => {
      return {
        value: key,
        text: this.$i18n.t('personal-information.TITLES.' + key).toString()
      } as KeyValue
    })
  }

  get course (): Form[] {
    return [
      {
        id: 'courseOfStudy',
        key: 'courseOfStudy',
        type: InputTypes.SELECT,
        label: this.$i18n.tc('student.my-data.courseOfStudies', 1).toString(),
        options: this.coursesOptions
      }
    ]
  }

  get emailFields (): Form[] {
    return [
      {
        id: 'fallbackMail',
        key: 'fallbackMail',
        type: InputTypes.EMAIL,
        label: this.$i18n.t('student.my-data.emailPrivate').toString(),
        disabled: true,
        edit: true
      }
    ]
  }

  get fields (): Form[] {
    return [
      {
        id: 'salutation',
        key: 'salutation',
        type: InputTypes.SELECT,
        label: this.$i18n.t('personal-information.salutation').toString(),
        options: this.salutations
      },
      {
        id: 'title',
        key: 'title',
        type: InputTypes.SELECT,
        label: this.$i18n.t('personal-information.title').toString(),
        options: this.titles
      },
      {
        id: 'firstname',
        key: 'firstname',
        type: InputTypes.TEXT,
        label: this.$i18n.t('personal-information.firstName').toString()
      },
      {
        id: 'lastname',
        key: 'lastname',
        type: InputTypes.TEXT,
        label: this.$i18n.t('personal-information.lastName').toString()
      }
    ]
  }

  get writeable (): boolean {
    return hasRight(Rights.STUDENT_OWN_APPLICATION_DATA_WRITE)
  }

  get readonly (): boolean {
    return hasRight(Rights.STUDENT_OWN_PROFILE_READ)
  }

  get hasPreviewRight (): boolean {
    return hasRight(STUDENT_OWN_VIEW)
  }

  mounted (): void {
    this.loadBaseInformation()

    this.getAllCourses().then((data: CourseOfStudy[]) => {
      this.courses = data
      this.coursesOptions = this.mapCoursesToKeyValue()
    })

    const root = document.getElementById('profile-editor')
    if (root !== null) {
      Unescape.setSoftHyphensInLabels(root)
    }
  }

  public save (): void {
    this.$root.$emit('load')
    this.setBaseInformation(this.baseInformation).then(() => {
      this.$root.$emit('alert', this.$i18n.t('info.save-success', { what: this.$i18n.t('main-menu-student.base-data') }))
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }

  mapCoursesToKeyValue (): KeyValue[] {
    return this.courses.map(course => ({ value: course.courseAbbreviation, text: this.$i18n.locale === 'de' ? course.deName : course.enName }))
  }

  public showPreview (): void {
    this.save()
    this.$nextTick(() => {
      const lang = this.$i18n.locale.toString()
      const routeData = this.$router.resolve({ name: 'talent-profile-preview', params: { id: this.userGetter.appUserId } })
      const target = window.location.origin + '/' + lang + routeData.href
      window.open(target, '_blank')
    })
  }

  loadBaseInformation (): void {
    this.$root.$emit('load')
    this.getBaseInformation().then((data: SimpleServerResponse) => {
      this.baseInformation = data.content
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }

  closeInfo (event: Event): void {
    event.preventDefault()
  }
}
